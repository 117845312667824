import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "./../../components/Header/Header.jsx";
import Footer from "./../../components/Footer/Footer.jsx";
import GridContainer from "./../../components/Grid/GridContainer.jsx";
import GridItem from "./../../components/Grid/GridItem.jsx";
import HeaderLinks from "./../../components/Header/HeaderLinks.jsx";
import Parallax from "./../../components/Parallax/Parallax.jsx";

import styles from "./../../assets/jss/material-kit-react/views/landingPage.jsx";
// Sections for this page
import ProductSection from "./../../pages/LandingPage/Sections/ProductSection.jsx";
import TeamSection from "./../../pages/LandingPage/Sections/TeamSection.jsx";
import WorkSection from "./../../pages/LandingPage/Sections/WorkSection.jsx";
import SectionCarousel from "./../../pages/LandingPage/Sections/SectionCarousel";
import LocationOn from "@material-ui/icons/LocationOn";
import withStyles from "@material-ui/core/styles/withStyles";
import {graphql, StaticQuery} from "gatsby";
import WelcomeSection from "./../../pages/LandingPage/Sections/WelcomeSection";
import ToGoSection from "./../../pages/LandingPage/Sections/ToGoSection";
import ContactSection from "../ContactPage/Sections/ContactSection";
import CookieSnackbar from "../Components/CookieSnackbar";

const pageQuery = graphql`
  {
    allGraphCmsLanding {
      nodes {
        header {
          localFile {
            localURL
          }  
        }
        lieferserviceImage {
          localFile {
            localURL
          }     
        }
        wirtshausImage {
          localFile {
            localURL
          }     
        }
        stadlImage {
          localFile {
            localURL
          }     
        }
        gallary {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }     
            fileName
          }
          title
        }      
        team {
          localFile {
            localURL
          }               
        } 
      }
    }  
    allGraphCmsAktuell {  
      nodes {
        toGo {
          image {
            localFile {
              localURL
            }                 
          }
          title
          text
          speisekartentext {
            html
          }
        }
      }
    }
  }
`;


class LandingPage extends React.Component {
    render() {
        const {classes, ...rest} = this.props;
        return <StaticQuery query={pageQuery}>{({allGraphCmsLanding, allGraphCmsAktuell}) => {
            const {header, gallary, team, wirtshausImage, lieferserviceImage, stadlImage} = allGraphCmsLanding.nodes[0]; // select first, whatever this is!
            const toGo = allGraphCmsAktuell != null && allGraphCmsAktuell.nodes.length >= 1 ? allGraphCmsAktuell.nodes[0].toGo : null; // select first, whatever this is!
            return <div style={{overflowX: "hidden"}}>
                <Header
                    color="transparent"
                    brand="Gasthaus Häring"
                    brandLink="/"
                    rightLinks={<HeaderLinks/>}
                    fixed
                    hideBrandBeforeScroll
                    changeColorOnScroll={{
                        height: 400,
                        color: "dark"
                    }}
                    {...rest}
                />
                <Parallax filter image={header.localFile.localURL}
                          style={{backgroundPosition: "center center"}}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <h1 className={classes.title}>Willkommen</h1>
                                <h4 className={classes.subtitle}
                                    onClick={() => window.open("https://goo.gl/maps/qhdNzQUJ5781RM9o8")}>
                                    <LocationOn className="slick-icons"/>
                                    <span>beim Wirt's Hubnstoa</span>
                                </h4>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classNames(classes.main/*, classes.mainRaised*/)}>
                    <div className={classes.containerNoMediaBreaks} style={{paddingLeft: 0, paddingRight: 0}}>
                        <ToGoSection toGo={toGo}/>
                        <WelcomeSection/>
                        <ProductSection lieferserviceImage={lieferserviceImage.localFile.localURL} wirtshausImage={wirtshausImage.localFile.localURL}
                                        stadlImage={stadlImage.localFile.localURL}/>
                        <SectionCarousel images={gallary}/>
                        <TeamSection imageUrl={team.localFile.localURL}/>
                        <ContactSection/>
                        {/*<WorkSection/>*/}
                    </div>
                </div>
                <Footer/>
                <CookieSnackbar/>
            </div>;
        }}</StaticQuery>
    }
}

/**
 * willkommen / liebe Gäste
 *      Willkommen auf der Website -
 *
 */



export default withStyles(styles)(LandingPage);
