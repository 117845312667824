import React from "react";

import styles from "./../../../assets/jss/material-kit-react/views/landingPageSections/toGoStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import {GatsbyImage} from "gatsby-plugin-image";


class ToGoSection extends React.Component {
  render() {
    const {classes, toGo} = this.props;
    if (toGo === null || toGo === undefined) {
        return null;
    }
    return (
        <div className={classes.section}>
            {toGo.title != null && toGo.title != "" ? <h2 className={classes.title}>{toGo.title}</h2> : "" }
            {toGo.text != null && toGo.text != "" ? <h4 className={classes.description}>{toGo.text}</h4> : ""}
            {toGo.image != null && toGo.image.localFile != null ? <img src={toGo.image.localFile.localURL + "?" + new Date().getTime()} alt="toGo Menü Karte" className={classes.image}/> : ""}
            {toGo.speisekartentext != null && toGo.speisekartentext != "" ? <div dangerouslySetInnerHTML={{ __html: toGo.speisekartentext }} /> : ""}
        </div>
    );
  }
}

export default withStyles(styles)(ToGoSection);
