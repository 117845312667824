import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

import styles from "./../../../assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";


class TeamSection extends React.Component {
    render() {
        const {classes, imageUrl} = this.props;
        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid,
            classes.imgSpacing
        );
        return (
            <div className={classes.section + " " + classes.container}>
                <h2 className={classes.title}>Wirtsleut</h2>
                <br/>
                <img src={imageUrl} alt="..." className={imageClasses}/>
                <br/><br/><br/>
                <h3 className={classes.cardTitle}>
                    Renate & Peter Häring
                </h3>
                <h4 className={classes.description}>
                    Das Wirtsehepaar führt das Gasthaus seit über 30 Jahren mit Leidenschaft und traditionellen Werten.<br/>
                    Der Familienbetrieb besteht aus drei Generationen und profitiert aus ihrer jahrelangen Erfahrung.
                </h4>
            </div>
        );
    }
}
export default withStyles(styles)(TeamSection);
