import React from "react";
// @material-ui/core components
// @material-ui/icons
// core components
import GridContainer from "./../../../components/Grid/GridContainer.jsx";
import GridItem from "./../../../components/Grid/GridItem.jsx";

import styles from "./../../../assets/jss/material-kit-react/views/landingPageSections/welcomeStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";


class WelcomeSection extends React.Component {
  render() {
    const {classes} = this.props;
    return (
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Liebe Gäste</h2>
              <h4 className={classes.description}>
                Wir freuen uns, Euch in unserem Gasthaus Willkommen zu heißen.
                Der Wirt z{"‘"}Hubenstoa ist seit knapp 100 Jahren im Familienbesitz der Familie Häring.
                Mit Leidenschaft und traditionell bewährter Küche bewirten wir Euch gerne und bieten verschiedene Lokalitäten für Anlässe jeglicher Art.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
    );
  }
}

export default withStyles(styles)(WelcomeSection);
