import React from "react";
// @material-ui/core components

// core components
import GridContainer from "./../../../components/Grid/GridContainer.jsx";
import GridItem from "./../../../components/Grid/GridItem.jsx";
import CustomInput from "./../../../components/CustomInput/CustomInput.jsx";
import Button from "./../../../components/CustomButtons/Button.jsx";

import styles from "./../../../assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";


class WorkSection extends React.Component {
  render() {
    const {classes} = this.props;
    return (
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={8}>
              <h2 className={classes.title}>Fragt uns an!</h2>
              <h4 className={classes.description}>
                Wenn ihr interessiert seit an einem Ort zum Feiern von Hochzeiten, Geburtstagen, oder sonstiges.
                Scheut euch nicht und ruft uns an, schreibt eine Email oder füllt unten eure Anfrage einfach aus.
              </h4>
              <form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                        labelText="Dein Name"
                        id="name"
                        formControlProps={{
                          fullWidth: true
                        }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                        labelText="Deine E-mail"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                    />
                  </GridItem>
                  <CustomInput
                      labelText="Deine Anfrage"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.textArea
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5
                      }}
                  />
                  <GridContainer justify="center" style={{width: "100%"}}>
                    <GridItem xs={12} sm={12} md={4} className={classes.textCenter}>
                      <Button color="primary">abschicken</Button>
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </form>
            </GridItem>
          </GridContainer>
        </div>
    );
  }
}

export default withStyles(styles)(WorkSection);
