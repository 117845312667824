import { title } from "assets/jss/material-kit-react.jsx";

const productStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#999"
  },
  imgFluid: {
    maxWidth: "100%",
    height: "auto"
  },
  imgRounded: {
    borderRadius: "6px !important"
  },
  imgRoundedCircle: {
    borderRadius: "50% !important"
  },
  imgHoverText: {
    color: "#999"
  },
  focusVisible: { },
  imageGridItem: {
    paddingLeft: "16px",
    paddingRight: "16px",
    "@media (max-width: 960px)": {
      paddingLeft: "200px",
      paddingRight: "200px",
    },
    "@media (max-width: 760px)": {
      paddingLeft: "160px",
      paddingRight: "160px",
    },
    "@media (max-width: 600px)": {
      paddingLeft: "60px",
      paddingRight: "60px",
    },
    "@media (max-width: 400px)": {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
    "@media (min-width: 960px)": {
      paddingLeft: "16px !important",
      paddingRight: "16px !important",
    },
    "@media (min-width: 1300px)": {
      paddingLeft: "40px !important",
      paddingRight: "40px !important",
    },
    "@media (min-width: 1800px)": {
      paddingLeft: "60px !important",
      paddingRight: "60px !important",
    }

  },
  image: {
    margin: "8px",
    position: 'relative',
    paddingTop: "100%",
    width: "100%",
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "white",
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "black",
    opacity: 0.4,
    transition: "opacity",
  },
  imageTitle: {
    position: 'relative',
    padding: `8px 16px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: "white",
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: "opacity",
  },

};

export default productStyle;
