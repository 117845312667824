import React from "react";
// @material-ui/core components
// @material-ui/icons
// core components
import GridContainer from "./../../../components/Grid/GridContainer.jsx";
import GridItem from "./../../../components/Grid/GridItem.jsx";

import styles from "./../../../assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import ButtonBase from "@material-ui/core/ButtonBase";
import withStyles from "@material-ui/core/styles/withStyles";


class ProductSection extends React.Component {
  render() {
    const {classes, lieferserviceImage, wirtshausImage, stadlImage} = this.props;
    return (
        <div className={classes.section}>
          <div className={classes.section}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} className={classes.imageGridItem}>
                <ButtonBase focusRipple key="Stadl" className={classes.image} href="/stadl"
                            focusVisibleClassName={classes.focusVisible} /*style={{width: image.width,}}*/>
                  <span className={classes.imageSrc + " " + classes.imgRoundedCircle}
                        style={{backgroundImage: `url(${stadlImage})`,}}/>
                  <span className={classes.imageBackdrop + " " + classes.imgRoundedCircle}/>
                  <span className={classes.imageButton}>
                <h2 component="span" variant="subtitle1" color="inherit" className={classes.imageTitle}>
                  Stadl<span className={classes.imageMarked}/>
                </h2>
              </span>
                </ButtonBase>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} className={classes.imageGridItem}>
                <ButtonBase focusRipple key="Wirtshaus" className={classes.image} href="/wirtshaus"
                            focusVisibleClassName={classes.focusVisible} /*style={{width: image.width,}}*/>
                  <span className={classes.imageSrc + " " + classes.imgRoundedCircle}
                        style={{backgroundImage: `url(${wirtshausImage})`,}}/>
                  <span className={classes.imageBackdrop + " " + classes.imgRoundedCircle}/>
                  <span className={classes.imageButton}>
                <h2 component="span" variant="subtitle1" color="inherit" className={classes.imageTitle}>
                  Wirtshaus<span className={classes.imageMarked}/>
                </h2>
              </span>
                </ButtonBase>
              </GridItem>
              <GridItem xs={12} sm={12} md={4} className={classes.imageGridItem}>
                <ButtonBase focusRipple key="Lieferservice" className={classes.image} href="/lieferservice"
                            focusVisibleClassName={classes.focusVisible} /*style={{width: image.width,}}*/>
                  <span className={classes.imageSrc + " " + classes.imgRoundedCircle}
                        style={{backgroundImage: `url(${lieferserviceImage})`,}}/>
                  <span className={classes.imageBackdrop + " " + classes.imgRoundedCircle}/>
                  <span className={classes.imageButton}>
                <h2 component="span" variant="subtitle1" color="inherit" className={classes.imageTitle}>
                  Lieferservice<span className={classes.imageMarked}/>
                </h2>
              </span>
                </ButtonBase>
              </GridItem>
            </GridContainer>
          </div>
        </div>
    );
  }
}

export default withStyles(styles)(ProductSection);
